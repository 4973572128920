import(/* webpackMode: "eager", webpackExports: ["AppLogic"] */ "/app/apps/web/app/[locale]/AppLogic.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/src/App/App.css");
;
import(/* webpackMode: "eager", webpackExports: ["DriftrockScript"] */ "/app/apps/web/src/Components/DriftrockScript/DriftrockScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GptScript"] */ "/app/apps/web/src/Components/GptScript/GptScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GtmScript"] */ "/app/apps/web/src/Components/GtmScript/GtmScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KwankoScript"] */ "/app/apps/web/src/Components/KwankoScript/KwankoScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SnowplowScript"] */ "/app/apps/web/src/Components/SnowplowScript/SnowplowScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ExternalAdScripts"] */ "/app/libs/ads/src/Components/ExternalAd/ExternalAdScripts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AdblockChecker"] */ "/app/libs/cmp/src/QuantcastAdblockChecker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QuantcastChoiceScript"] */ "/app/libs/cmp/src/QuantcastChoiceScript.tsx");
;
import(/* webpackMode: "eager" */ "/app/libs/cmp/src/QuantcastSsrPlaceholder.css");
;
import(/* webpackMode: "eager", webpackExports: ["DevToolsListener"] */ "/app/libs/dev-tools/src/DevToolsListener.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EventViewer"] */ "/app/libs/dev-tools/src/EventViewer/EventViewer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlagminWrapper"] */ "/app/libs/dev-tools/src/Flagmin/FlagminWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/libs/gds/src/Icons/Styles/Default.module.css");
;
import(/* webpackMode: "eager" */ "/app/libs/gds/src/Themes/default.css");
;
import(/* webpackMode: "eager", webpackExports: ["LeadFormModals"] */ "/app/libs/lead-forms/src/Components/LeadForm/LeadFormModals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NativeClientLogic"] */ "/app/libs/mobile-app/src/Components/NativeLogic/NativeClientLogic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccountFormModals"] */ "/app/libs/my-account/src/Components/AccLoginForm/AccountFormModals.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastManager"] */ "/app/libs/shared-components/src/Toast/ToastManager.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.4_@babel+core@7.24.9_@playwright+test@1.50.0_babel-plugin-macros@3.1.0_react-dom@18_3oqdkqizculhgkxxjnqwpxmb5y/node_modules/next/font/local/target.css?{\"path\":\"src/Utils/App/Fonts/Objektiv.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"block\",\"preload\":true,\"variable\":\"--font-family-system\",\"fallback\":[\"Objektiv\",\"sans-serif\"],\"src\":[{\"path\":\"./objektivmk3_regular.woff2\",\"weight\":\"100\"},{\"path\":\"./objektivmk3_regular.woff2\",\"weight\":\"400\"},{\"path\":\"./objektivmk3_regular.woff2\",\"weight\":\"normal\"},{\"path\":\"./objektivmk3_medium.woff2\",\"weight\":\"500\"},{\"path\":\"./objektivmk3_bold.woff2\",\"weight\":\"600 700\"},{\"path\":\"./objektivmk3_xbold.woff2\",\"weight\":\"bold\"}]}],\"variableName\":\"objektiv\"}");
