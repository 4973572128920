'use client';

import { useEffect, useState } from 'react';

import { useAtomValue } from 'jotai';
import dynamic from 'next/dynamic';

import { userProfileAtom } from '@my-account/Atoms/UserProfile.atom';

import { isEventViewerActiveAtom } from '../DevToolsListener';

const EventViewerModal = dynamic(
  () => import('./EventViewerModal').then(mod => mod.EventViewerModal),
  {
    ssr: false,
  },
);

export const EventViewer = () => {
  const isActive = useAtomValue(isEventViewerActiveAtom);
  const { data: userProfile } = useAtomValue(userProfileAtom);

  const [hasBeenActivated, setHasBeenActivated] = useState(false);
  const isProd = process.env.NEXT_PUBLIC_APP_ENV === 'production';

  useEffect(() => {
    if (isProd && !userProfile?.email.includes('@heycar.')) return;
    if (isActive) setHasBeenActivated(true);
  }, [isActive]);

  if (hasBeenActivated) return <EventViewerModal />;

  return null;
};
