'use client';
import { useEffect } from 'react';

import { useSetAtom } from 'jotai';
import Cookies from 'js-cookie';
import { usePathname, useSearchParams } from 'next/navigation';

import {
  checkShouldHideLoader,
  getScreenTitleFromPath,
} from '@native-navigation/Utils/NativeScreenTypeMap';
import { sendNativeMessage } from '@native-navigation/Utils/SendNativeMessage';
import { initialAttributionToEvent } from '@tracking/Schemas/Attribution/InitialAttributionToEvent';
import { latestAttributionToEvent } from '@tracking/Schemas/Attribution/LatestAttributionToEvent';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { isMockUiEnabledAtom } from '../../Atoms/MockNativeUiAtoms';

import { NativeMessageBridge } from './NativeMessageBridge';

export const NativeClientLogic = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const isMockUiEnabled = useSetAtom(isMockUiEnabledAtom);

  const pathnameWithoutAutosParams = pathname.replace(/\/autos.*/g, '/autos');

  useEffect(() => {
    const isMockUiEnabledCookieValue = Cookies.get('showMockUi') === 'on';
    const initialAttributionParams = Cookies.get('initialAttributionParams');
    const latestAttributionParams = Cookies.get('latestAttributionParams');
    isMockUiEnabled(isMockUiEnabledCookieValue);

    if (initialAttributionParams) {
      try {
        const unescapedInitialAttributionParams = initialAttributionParams.replace(/\\"/g, '"');
        const initialAttributionData = JSON.parse(unescapedInitialAttributionParams);
        trackCustomEvent({ event: initialAttributionToEvent(initialAttributionData), context: [] });
      } catch (e) {
        //
      }
    } else {
      console.warn('initialAttributionParams is not available in the cookies.');
    }

    if (latestAttributionParams) {
      try {
        const unescapedString = latestAttributionParams.replace(/\\"/g, '"');

        const latestAttributionData = JSON.parse(unescapedString);
        trackCustomEvent({ event: latestAttributionToEvent(latestAttributionData), context: [] });
      } catch (error) {
        //
      }
    } else {
      console.warn('latestAttributionParams is not available in the cookies.');
    }
  }, []);

  useEffect(() => {
    sendNativeMessage({
      updateHeader: getScreenTitleFromPath(pathname),
      ...(pathname.includes('/checkout/') &&
        !pathname.includes('/summary') && { showBackButton: false }),
    });

    if (checkShouldHideLoader(pathname)) sendNativeMessage({ hideLoader: true });

    const body = document.querySelector('body');
    if (body) body.setAttribute('data-is-ready', 'true');
  }, [pathname, searchParams]);

  useEffect(() => {
    if (!pathname.includes('/checkout/') || pathname.includes('/summary')) {
      sendNativeMessage({ showBackButton: true });
    }
  }, [pathnameWithoutAutosParams]);

  return <NativeMessageBridge />;
};
